define('ember-sticky-element/components/sticky-element/trigger', ['exports', 'ember-in-viewport'], function (exports, _emberInViewport) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_emberInViewport.default, {
    classNameBindings: ['typeClass'],
    classNames: ['sticky-element__trigger'],

    /**
     * @property type
     * @type {string}
     * @default 'top'
     * @public
     */
    type: 'top',

    /**
     * @property offset
     * @type {number}
     * @public
     */
    offset: 0,

    /**
     * @property typeClass
     * @type string
     * @private
     */
    typeClass: Ember.computed('type', function () {
      return 'sticky-element__trigger--' + this.get('type');
    }),

    _lastTop: null,

    /**
     * Action when trigger enters viewport
     *
     * @event enter
     * @public
     */

    /**
     * Action when trigger exits viewport
     *
     * @event exit
     * @param {Boolean} top True if element left the viewport from the top
     * @public
     */

    isBeforeViewport: function isBeforeViewport() {
      var offset = this.get('type') === 'top' ? this.get('offset') : 0;
      return this.get('element').getBoundingClientRect().top - offset < 0;
    },
    didEnterViewport: function didEnterViewport() {
      this.enter();
    },
    didExitViewport: function didExitViewport() {
      this.exit();
    },


    /**
     * @method updateViewportOptions
     * @private
     */
    updateViewportOptions: function updateViewportOptions() {
      var viewportTolerance = {
        top: 0,
        bottom: 0,
        left: 0,
        right: 0
      };
      viewportTolerance[this.get('type')] = -this.get('offset');
      Ember.setProperties(this, {
        viewportSpy: true,
        viewportEnabled: true,
        viewportTolerance: viewportTolerance
      });

      this.updateIntersectionObserver();
    },


    /**
     * Updates intersectionObserver after options have changed
     *
     * @method updateIntersectionObserver
     * @private
     */
    updateIntersectionObserver: function updateIntersectionObserver() {
      if (this.intersectionObserver) {
        this.intersectionObserver.unobserve(this.element);
        this._setViewportEntered();
      }
    },
    init: function init() {
      this._super.apply(this, arguments);
      this.updateViewportOptions();
    },
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      this.registerElement(this.element);
    },


    _onOffsetChange: Ember.observer('offset', function () {
      Ember.run.scheduleOnce('afterRender', this, this.updateViewportOptions);
    }),

    _bindScrollDirectionListener: function _bindScrollDirectionListener() {},
    _unbindScrollDirectionListener: function _unbindScrollDirectionListener() {},


    /**
     * Override ember-in-viewport method to trigger event also when trigger has moved from below viewport to on top
     * of viewport without triggering didEnterViewport because of too fast scroll movement
     *
     * @method _triggerDidAccessViewport
     * @param hasEnteredViewport
     * @private
     */
    _triggerDidAccessViewport: function _triggerDidAccessViewport() {
      var hasEnteredViewport = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : false;

      var viewportEntered = this.get('viewportEntered');
      var didEnter = !viewportEntered && hasEnteredViewport;
      var didLeave = viewportEntered && !hasEnteredViewport;

      var lastTop = this._lastTop;
      this._lastTop = this.isBeforeViewport();

      if (!didEnter && !didLeave) {
        if (lastTop !== this._lastTop) {
          this._super(true);
          this._super(false);
        }
      } else {
        this._super(hasEnteredViewport);
      }
    }
  });
});