define('ember-in-viewport/mixins/in-viewport', ['exports', 'ember-in-viewport/utils/can-use-dom', 'ember-in-viewport/utils/can-use-raf', 'ember-in-viewport/utils/find-elem', 'ember-in-viewport/utils/can-use-intersection-observer', 'ember-in-viewport/utils/is-in-viewport', 'ember-in-viewport/utils/check-scroll-direction'], function (exports, _canUseDom, _canUseRaf, _findElem, _canUseIntersectionObserver, _isInViewport, _checkScrollDirection) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _toConsumableArray(arr) {
    if (Array.isArray(arr)) {
      for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
        arr2[i] = arr[i];
      }

      return arr2;
    } else {
      return Array.from(arr);
    }
  }

  var _slicedToArray = function () {
    function sliceIterator(arr, i) {
      var _arr = [];
      var _n = true;
      var _d = false;
      var _e = undefined;

      try {
        for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
          _arr.push(_s.value);

          if (i && _arr.length === i) break;
        }
      } catch (err) {
        _d = true;
        _e = err;
      } finally {
        try {
          if (!_n && _i["return"]) _i["return"]();
        } finally {
          if (_d) throw _e;
        }
      }

      return _arr;
    }

    return function (arr, i) {
      if (Array.isArray(arr)) {
        return arr;
      } else if (Symbol.iterator in Object(arr)) {
        return sliceIterator(arr, i);
      } else {
        throw new TypeError("Invalid attempt to destructure non-iterable instance");
      }
    };
  }();

  var rAFIDS = {};
  var lastDirection = {};
  var lastPosition = {};

  exports.default = Ember.Mixin.create({
    /**
     * IntersectionObserverEntry
     *
     * https://developer.mozilla.org/en-US/docs/Web/API/IntersectionObserverEntry
     *
     * @property intersectionObserver
     * @default null
     */
    intersectionObserver: null,
    /**
     * @property _debouncedEventHandler
     * @default null
     */
    _debouncedEventHandler: null,

    /**
     * unbinding listeners will short circuit rAF
     *
     * @property _stopListening
     * @default false
     */
    _stopListening: false,

    rAFPoolManager: Ember.inject.service('-in-viewport'),

    /**
     * @property viewportExited
     * @type Boolean
     */
    viewportExited: Ember.computed.not('viewportEntered').readOnly(),

    init: function init() {
      // ensure this mixin runs first, then your component can override the options
      this._super.apply(this, arguments);

      var options = Ember.assign({
        viewportUseRAF: (0, _canUseRaf.default)(),
        viewportUseIntersectionObserver: (0, _canUseIntersectionObserver.default)(),
        viewportEntered: false,
        viewportListeners: []
      }, this._buildOptions());

      Ember.setProperties(this, options);
      Ember.set(this, '_evtListenerClosures', []);
    },
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      if (!_canUseDom.default) {
        return;
      }

      var viewportEnabled = Ember.get(this, 'viewportEnabled');
      if (viewportEnabled) {
        this._startListening();
      }
    },
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);
      this._unbindListeners();
    },
    _buildOptions: function _buildOptions() {
      var defaultOptions = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};

      var owner = Ember.getOwner(this);

      if (owner) {
        return Ember.assign(defaultOptions, owner.lookup('config:in-viewport'));
      }
    },
    _startListening: function _startListening() {
      var _this = this;

      this._setInitialViewport();
      this._addObserverIfNotSpying();
      this._bindScrollDirectionListener(Ember.get(this, 'viewportScrollSensitivity'));

      if (!Ember.get(this, 'viewportUseIntersectionObserver') && !Ember.get(this, 'viewportUseRAF')) {
        Ember.get(this, 'viewportListeners').forEach(function (listener) {
          var context = listener.context,
              event = listener.event;

          context = Ember.get(_this, 'scrollableArea') || context;
          _this._bindListeners(context, event);
        });
      }
    },
    _addObserverIfNotSpying: function _addObserverIfNotSpying() {
      if (!Ember.get(this, 'viewportSpy')) {
        this.addObserver('viewportEntered', this, this._unbindIfEntered);
      }
    },
    _setInitialViewport: function _setInitialViewport() {
      var _this2 = this;

      if (Ember.get(this, 'viewportUseIntersectionObserver')) {
        return Ember.run.scheduleOnce('afterRender', this, function () {
          _this2._setupIntersectionObserver();
        });
      } else {
        return Ember.run.scheduleOnce('afterRender', this, function () {
          _this2._setViewportEntered();
        });
      }
    },


    /**
     * @method _setupIntersectionObserver
     */
    _setupIntersectionObserver: function _setupIntersectionObserver() {
      var scrollableArea = Ember.get(this, 'scrollableArea') ? document.querySelector(Ember.get(this, 'scrollableArea')) : null;

      var element = Ember.get(this, 'element');
      if (!element) {
        return;
      }

      // https://developer.mozilla.org/en-US/docs/Web/API/Intersection_Observer_API
      // IntersectionObserver takes either a Document Element or null for `root`
      var _viewportTolerance = this.viewportTolerance,
          _viewportTolerance$to = _viewportTolerance.top,
          top = _viewportTolerance$to === undefined ? 0 : _viewportTolerance$to,
          _viewportTolerance$le = _viewportTolerance.left,
          left = _viewportTolerance$le === undefined ? 0 : _viewportTolerance$le,
          _viewportTolerance$bo = _viewportTolerance.bottom,
          bottom = _viewportTolerance$bo === undefined ? 0 : _viewportTolerance$bo,
          _viewportTolerance$ri = _viewportTolerance.right,
          right = _viewportTolerance$ri === undefined ? 0 : _viewportTolerance$ri;

      var options = {
        root: scrollableArea,
        rootMargin: top + 'px ' + right + 'px ' + bottom + 'px ' + left + 'px',
        threshold: Ember.get(this, 'intersectionThreshold')
      };

      this.intersectionObserver = new IntersectionObserver(Ember.run.bind(this, this._onIntersection), options);
      this.intersectionObserver.observe(element);
    },


    /**
     * used by rAF and scroll event listeners to determine if mixin is in viewport
     * Remember to set `viewportSpy` to true if you want to continuously observe your element
     *
     * @method _setViewportEntered
     */
    _setViewportEntered: function _setViewportEntered() {
      var scrollableArea = Ember.get(this, 'scrollableArea') ? document.querySelector(Ember.get(this, 'scrollableArea')) : null;

      var element = Ember.get(this, 'element');
      if (!element) {
        return;
      }

      var height = scrollableArea ? scrollableArea.offsetHeight + scrollableArea.getBoundingClientRect().top : window.innerHeight;
      var width = scrollableArea ? scrollableArea.offsetWidth : window.innerWidth;
      var boundingClientRect = element.getBoundingClientRect();

      if (boundingClientRect) {
        this._triggerDidAccessViewport((0, _isInViewport.default)(boundingClientRect, height, width, Ember.get(this, 'viewportTolerance')));

        if (Ember.get(this, 'viewportUseRAF') && !Ember.get(this, '_stopListening')) {
          var elementId = Ember.get(this, 'elementId');
          rAFIDS[elementId] = Ember.get(this, 'rAFPoolManager').add(elementId, Ember.run.bind(this, this._setViewportEntered));
        }
      }
    },


    /**
     * callback provided to IntersectionObserver
     *
     * @method _onIntersection
     * @param {Array} - entries
     */
    _onIntersection: function _onIntersection(entries) {
      var isTearingDown = this.isDestroyed || this.isDestroying;

      var _entries = _slicedToArray(entries, 1),
          entry = _entries[0];

      var isIntersecting = entry.isIntersecting,
          intersectionRatio = entry.intersectionRatio;


      if (isIntersecting) {
        if (!isTearingDown) {
          Ember.set(this, 'viewportEntered', true);
        }
        this.trigger('didEnterViewport');
      } else if (intersectionRatio <= 0) {
        // exiting viewport
        if (!isTearingDown) {
          Ember.set(this, 'viewportEntered', false);
        }
        this.trigger('didExitViewport');
      }
    },


    /**
     * @method _triggerDidScrollDirection
     * @param contextEl
     * @param sensitivity
     */
    _triggerDidScrollDirection: function _triggerDidScrollDirection() {
      var contextEl = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : null;
      var sensitivity = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 1;
      (true && !(contextEl) && Ember.assert('You must pass a valid context element to _triggerDidScrollDirection', contextEl));
      (true && !(sensitivity) && Ember.assert('sensitivity cannot be 0', sensitivity));


      var elementId = Ember.get(this, 'elementId');
      var lastDirectionForEl = lastDirection[elementId];
      var lastPositionForEl = lastPosition[elementId];
      var newPosition = {
        top: contextEl.scrollTop,
        left: contextEl.scrollLeft
      };

      var scrollDirection = (0, _checkScrollDirection.default)(lastPositionForEl, newPosition, sensitivity);
      var directionChanged = scrollDirection !== lastDirectionForEl;

      if (scrollDirection && directionChanged && Ember.get(this, 'viewportEntered')) {
        this.trigger('didScroll', scrollDirection);
        lastDirection[elementId] = scrollDirection;
      }

      lastPosition[elementId] = newPosition;
    },


    /**
     * @method _triggerDidAccessViewport
     * @param hasEnteredViewport
     */
    _triggerDidAccessViewport: function _triggerDidAccessViewport() {
      var hasEnteredViewport = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : false;

      var viewportEntered = Ember.get(this, 'viewportEntered');
      var didEnter = !viewportEntered && hasEnteredViewport;
      var didLeave = viewportEntered && !hasEnteredViewport;
      var triggeredEventName = '';

      if (didEnter) {
        triggeredEventName = 'didEnterViewport';
      }

      if (didLeave) {
        triggeredEventName = 'didExitViewport';
      }

      if (Ember.get(this, 'viewportSpy') || !viewportEntered) {
        Ember.set(this, 'viewportEntered', hasEnteredViewport);
      }

      this.trigger(triggeredEventName);
    },
    _unbindIfEntered: function _unbindIfEntered() {
      if (!Ember.get(this, 'viewportSpy') && Ember.get(this, 'viewportEntered')) {
        this._unbindListeners();
        this.removeObserver('viewportEntered', this, this._unbindIfEntered);
        Ember.set(this, 'viewportEntered', false);
      }
    },


    /**
     * General utility function
     *
     * @method _debouncedEvent
     */
    _debouncedEvent: function _debouncedEvent(methodName) {
      var _this3 = this;

      for (var _len = arguments.length, args = Array(_len > 1 ? _len - 1 : 0), _key = 1; _key < _len; _key++) {
        args[_key - 1] = arguments[_key];
      }

      (true && !(methodName) && Ember.assert('You must pass a methodName to _debouncedEvent', methodName));
      (true && !(Ember.typeOf(methodName) === 'string') && Ember.assert('methodName must be a string', Ember.typeOf(methodName) === 'string'));


      Ember.run.debounce(this, function () {
        return _this3[methodName].apply(_this3, _toConsumableArray(args));
      }, Ember.get(this, 'viewportRefreshRate'));
    },
    _bindScrollDirectionListener: function _bindScrollDirectionListener() {
      var sensitivity = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 1;
      (true && !(sensitivity) && Ember.assert('sensitivity cannot be 0', sensitivity));


      var contextEl = Ember.get(this, 'scrollableArea') || window;
      var elem = (0, _findElem.default)(contextEl);

      this._debouncedEventHandler = this._debouncedEvent.bind(this, '_triggerDidScrollDirection', elem, sensitivity);
      elem.addEventListener('scroll', this._debouncedEventHandler, false);
    },
    _unbindScrollDirectionListener: function _unbindScrollDirectionListener() {
      var elementId = Ember.get(this, 'elementId');
      var context = Ember.get(this, 'scrollableArea') || window;
      var elem = (0, _findElem.default)(context);

      if (elem) {
        elem.removeEventListener('scroll', this._debouncedEventHandler, false);
        delete lastPosition[elementId];
        delete lastDirection[elementId];
      }
    },


    /**
     * Only if not using IntersectionObserver and rAF
     *
     * @method _bindListeners
     */
    _bindListeners: function _bindListeners() {
      var _this4 = this;

      var context = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : null;
      var event = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : null;
      (true && !(context) && Ember.assert('You must pass a valid context to _bindListeners', context));
      (true && !(event) && Ember.assert('You must pass a valid event to _bindListeners', event));


      var elem = (0, _findElem.default)(context);

      var evtListener = function evtListener() {
        return _this4._debouncedEvent('_setViewportEntered');
      };
      this._evtListenerClosures.push({ event: event, evtListener: evtListener });
      elem.addEventListener(event, evtListener);
    },


    /**
     * @method _unbindListeners
     */
    _unbindListeners: function _unbindListeners() {
      var _this5 = this;

      Ember.set(this, '_stopListening', true);

      // 1.
      if (this.intersectionObserver) {
        this.intersectionObserver.unobserve(this.element);
      }

      // 2.
      if (!Ember.get(this, 'viewportUseIntersectionObserver') && Ember.get(this, 'viewportUseRAF')) {
        var elementId = Ember.get(this, 'elementId');

        Ember.run.next(this, function () {
          Ember.get(_this5, 'rAFPoolManager').remove(elementId);
          delete rAFIDS[elementId];
        });
      }

      // 3.
      if (!Ember.get(this, 'viewportUseIntersectionObserver') && !Ember.get(this, 'viewportUseRAF')) {
        Ember.get(this, 'viewportListeners').forEach(function (listener) {
          var context = listener.context,
              event = listener.event;

          context = Ember.get(_this5, 'scrollableArea') || context;
          var elem = (0, _findElem.default)(context);

          var _ref = _this5._evtListenerClosures.find(function (closure) {
            return event === closure.event;
          }) || {},
              evtListener = _ref.evtListener;

          elem.removeEventListener(event, evtListener);
        });
      }

      // 4.
      this._unbindScrollDirectionListener();
    }
  });
});